<script>
export default {
  created() {
    this.downloadFile()
  },
  methods: {
    downloadFile() {
      const content = 'e3aaef2510902b606292594f86139ef5a2633394e442abab2a5bdfacc87b587dQl7xVAjFipiVPsWVxFgZ8arGxgOOJIQRcPKXidetcdkL1oVGKY8wMUBUxFt9TMdx'
      const blob = new Blob([content], { type: 'text/plain' })
      const url = window.URL.createObjectURL(blob)

      const a = document.createElement('a')
      a.href = url
      a.download = '5b019f98b99872b1355a46e5afd7911c.txt'
      document.body.appendChild(a)
      a.click()
      document.body.removeChild(a)
      window.URL.revokeObjectURL(url)

      this.$router.push('/')
    },
  },
}
</script>
